
import { computed, defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "RequestEdit",
  setup() {
    const store = useStore();
    onMounted(() => {
      setCurrentPageTitle("Хүсэлт");
    });

    const isu = computed(() => {
      return store.getters.getMbUser;
    });

    const role = computed(() => {
      return store.getters.getRoleNames;
    });

    return {
      isu,
      role
    };
  },
  mounted() {
    if (this.isu) this.getRequests();
  },
  data() {
    return {
      page: 1,
      pageSize: 10,
      loadingtable: false,
      customerSearch: "",
      tableData: []
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
    },
    handleChange(value) {
      this.pageSize = value;
    },
    sendOnce(type, id) {
      if (this.tableData.length === 0) {
        ElNotification({
          title: "Анхааруулга",
          message: "Хүсэлт байхгүй байна.",
          type: "warning",
          iconClass: "el-text-warning"
        });
        return;
      }
      Swal.fire({
        title: "Үйлдлийг зөвшөөрөх үү?",
        showCancelButton: true,
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй"
      }).then(async result => {
        if (result.isConfirmed) {
          this.loadingtable = true;
          const params = {
            type: type,
            id: id
          };
          await ApiService.post("updaterequest", { params })
            .then(resp => {
              const results = resp.data;
              if (results.code === 0) {
                ElNotification({
                  title: "Алдаа",
                  message: results.error,
                  type: "error",
                  iconClass: "el-text-error"
                });
              } else if (results.code === 401) {
                ElNotification({
                  title: "Анхааруулга",
                  message: results.warning,
                  type: "warning",
                  iconClass: "el-text-warning"
                });
              } else {
                ElNotification({
                  title: "Амжилттай",
                  message: results.response,
                  type: "success",
                  iconClass: "el-text-success"
                });
                this.tableData = this.tableData.filter(el => {
                  return el["id"] !== id;
                });
              }
            })
            .catch(() => {
              ElNotification({
                title: "Алдаа",
                message: "Алдаа гарлаа.",
                type: "error",
                iconClass: "el-text-error"
              });
            })
            .finally(() => {
              this.loadingtable = false;
            });
        }
      });
    },
    async sendAll(type) {
      if (this.tableData.length === 0) {
        ElNotification({
          title: "Анхааруулга",
          message: "Хүсэлт байхгүй байна.",
          type: "warning",
          iconClass: "el-text-warning"
        });
        return;
      }
      Swal.fire({
        title: "Үйлдлийг зөвшөөрөх үү?",
        showCancelButton: true,
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй"
      }).then(async result => {
        if (result.isConfirmed) {
          this.loadingtable = true;
          const params = {
            type: type,
            id: type
          };
          const data = [];
          this.tableData.forEach(it => {
            data.push(it["id"]);
          });
          await ApiService.postWithRawBody(
            "updaterequest",
            { data },
            { params }
          )
            .then(resp => {
              const results = resp.data;
              if (results.code === 0) {
                ElNotification({
                  title: "Алдаа",
                  message: results.error,
                  type: "error",
                  iconClass: "el-text-error"
                });
              } else if (results.code === 401) {
                ElNotification({
                  title: "Анхааруулга",
                  message: results.warning,
                  type: "warning",
                  iconClass: "el-text-warning"
                });
              } else {
                const arr = results.response;
                const len = arr.length;
                let html = "<ol>",
                  x = 0;
                while (x < len) {
                  html +=
                    "<li><b>" +
                    arr[x]["reqid"] +
                    "</b> - " +
                    arr[x]["result"] +
                    "</li>";
                  if (arr[x]["success"])
                    this.tableData = this.tableData.filter(el => {
                      return el["id"] !== arr[x]["reqid"];
                    });
                  ++x;
                }
                html += "</ol>";
                ElNotification({
                  title: "Амжилттай",
                  dangerouslyUseHTMLString: true,
                  message: html,
                  type: "success",
                  iconClass: "el-text-success"
                });
              }
            })
            .catch(() => {
              ElNotification({
                title: "Алдаа",
                message: "Алдаа гарлаа.",
                type: "error",
                iconClass: "el-text-error"
              });
            })
            .finally(() => {
              this.loadingtable = false;
            });
        }
      });
    },
    async getRequests() {
      this.loadingtable = true;
      await ApiService.get(`updaterequest`)
        .then(resp => {
          const results = resp.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error",
              iconClass: "el-text-error"
            });
          } else if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else {
            this.tableData = results.response.map(it => ({
              purpose: it.purpose,
              createddate: it.createddate,
              createdby: it.createdby,
              customername: it.customername,
              registerno: it.registerno,
              tablename: it.tablename,
              updatecolumn: it.updatecolumn,
              status: it.status,
              statusValue: it.statusValue,
              reviseddate: it.reviseddate,
              revisedby: it.revisedby,
              decideddate: it.decideddate,
              decidedby: it.decidedby,
              id: it.id,
              keyvalue: it.keyvalue,
              type: it.type,
              orgcode: it.orgcode,
              newvalue: it.newvalue,
              oldvalue: it.oldvalue,
              fieldtype: it.fieldtype,
              newValueString: it.newValueString,
              oldValueString: it.oldValueString,
              searchfield: it.searchfield
            }));
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          this.loadingtable = false;
        });
    }
  }
});
